<template>
  <div class="w-100">
    <b-overlay spinner-variant="primary" :show="isLoading">
      <div class="d-flex mb-2">
        <b-button variant="primary" size="lg" @click="addPupUpShow = true">
          <feather-icon class="ml-0" icon="PlusIcon" size="20" /> Додати відділ
          чи посаду
        </b-button>
      </div>
      <h1>Перелік доступних відділів і посад: {{ departments.length }} шт.</h1>
      <p class="mb-1">
        Житель зможе обрати в кому з посадовців направити звернення. <br />Також
        працівника можна призначити на посаду і він зможе бачити направлені йому
        запити і звернення
      </p>
      <div class="mb-1 d-flex align-items-center">
        <b-form-select class="w-25 mr-1" v-model="selected_group">
          <b-form-select-option value="all"> Всі </b-form-select-option>
          <b-form-select-option value="departments">
            Відділи
          </b-form-select-option>
          <b-form-select-option value="positions">
            Посади
          </b-form-select-option>
        </b-form-select>
        <b-form-input
          v-model="filterQuery"
          placeholder="Фільтрувати за назвою"
        />
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <small>
            Результатів: <strong>{{ filteredDepartments.length }}</strong>
          </small>
        </div>
      </div>
      <div class="row">
        <router-link
          v-for="d in filteredDepartments"
          :key="d.uid"
          class="d-block col-12 align-items-center"
          :to="{
            name: 'departments-single',
            params: {
              id: d.id,
            },
          }"
        >
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <feather-icon
                icon="ArchiveIcon"
                v-if="!d.is_position"
                size="24"
                class="mr-2"
              />
              <feather-icon
                icon="UserIcon"
                v-if="d.is_position"
                size="24"
                class="mr-2"
              />
              <div>
                <h4 class="m-0" v-html="highlight(d.name)"></h4>
                <span class="parent-department" v-if="d.is_position">
                  {{ getParentDepartment(d.parent) }}
                </span>
              </div>
            </div>
          </b-card>
        </router-link>
      </div>
    </b-overlay>
    <b-modal
      v-model="addPupUpShow"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-overlay :show="addingLoading">
        <h2 class="mb-2 mt-2">
          <feather-icon icon="PlusIcon" size="24" /> Додавання нового відділу
        </h2>
        <div class="form-group">
          <label>Назва</label>
          <b-form-input class="mb-2" v-model.trim="new_department.name" />
          <b-form-checkbox v-model="new_department.is_position">
            Є посадою
          </b-form-checkbox>
        </div>
        <div class="form-group">
          <label for=""> Предок </label>
          <v-select
            id="department-parent"
            v-model="selectedParent"
            :options="parentDepartments"
            label="text"
            placeholder="Оберіть основний відділ-предок (необов'язково)"
          />
        </div>
        <b-alert variant="danger p-1" :show="!!nameExists">
          Відділ з таким ім'ям вже існує
        </b-alert>
        <div class="form-group">
          <b-button
            :disabled="!!nameExists || !new_department.name"
            block
            variant="primary"
            size="lg"
            @click="createDepartment"
          >
            <span v-if="new_department.is_position">Додати посаду</span>
            <span v-else>Додати відділ</span>
          </b-button>
          <b-button
            block
            variant="outline-danger"
            size="lg"
            @click="addPupUpShow = false"
          >
            Скасувати
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      filterQuery: "",
      addingLoading: false,
      isLoading: false,
      departments: [],
      addPupUpShow: false,
      selectedParent: null,
      selected_group: "positions",
      new_department: {
        name: "",
        id: null,
        parent: null,
      },
    };
  },
  computed: {
    filteredDepartments() {
      var found = this.departments;
      if (this.selected_group == "departments") {
        found = found.filter((el) => {
          return !el.is_position;
        });
      }

      if (this.selected_group == "positions") {
        found = found.filter((el) => {
          return el.is_position;
        });
      }

      if (this.filterQuery.length) {
        found = found.filter((el) => {
          return (
            el.name.toLowerCase().indexOf(this.filterQuery.toLowerCase()) > -1
          );
        });
      }
      return found;
    },
    nameExists() {
      var exists = false;
      exists = this.departments.find(
        (element) => element.name == this.new_department.name
      );

      return exists;
    },
    parentDepartments() {
      var list = this.departments.map((e) => {
        return { text: e.name, value: e.id };
      });
      // list.unshift({ text: "Немає", value: null });
      return list;
    },
  },
  methods: {
    getParentDepartment(id) {
      let result = this.departments.find((el) => {
        return el.id == id;
      });
      return result.name;
    },
    async getDepartments() {
      this.isLoading = true;
      var args = {
        collectionName: "departments",
      };
      var result = await this.$db.getCollection(args);
      this.departments = result.docs;
      this.isLoading = false;
    },
    highlight(value) {
      var value = value.toString();
      var term = this.filterQuery.toString();
      if (term.length > 1) {
        var reg = new RegExp(term, "gi");
        value = value.replace(reg, function (str) {
          return '<strong class="search-excerpt">' + str + "</strong>";
        });
        return value;
      } else {
        return value;
      }
    },
    async createDepartment() {
      this.addingLoading = true;
      var id = await this.$api
        .get("/get/counter/?type=departments")
        .then((response) => {
          return response.data.count;
        })
        .catch((error) => {
          alert(error);
        });
      var result = false;
      if (id) {
        this.new_department.id = id;
        if (this.selectedParent) {
          this.new_department.parent = this.selectedParent.value;
        } else {
          this.new_department.parent = null;
        }
        result = await this.$db.add("departments", this.new_department);
        if (result.success) {
          this.$toast.success("Відділ створено");
          this.addPupUpShow = false;
          this.new_department = {
            name: "",
            id: null,
            parent: null,
          };
        }
      }

      this.addingLoading = false;
      this.getDepartments();
    },
  },
  mounted() {
    this.getDepartments();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
.search-excerpt {
  background: yellow;
}
</style>
